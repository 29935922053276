.App {
  text-align: center;
  min-height: 95vh; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.paper {
  marginTop: 5rem;
  display: 'flex';
  flexDirection: 'column';
  alignItems: 'center';
}

.avatar{
  margin: 4rem 11rem;
}

.form {
  width: '100%', // Fix IE 11 issue;
  line-height: 5rem;
}

.success{
  color:  green;
}

.error{
  color: red;
}

.Wait-page{
  margin-top: 15rem;
}

.Schemes{
    width: 80%;
    /*background-color: rgba(230, 230, 230, 0.7) !important;*/
    margin-left: 10% !important;
    max-height: 500px;
    overflow: auto;
}

.Logo {
  height: 30px
}

.Bar {
   background-color : #44556E !important;
}

.Footer {
    /*background-color: grey;*/
    /*padding: 3rem;*/
}

/* On scheme seletion, set the cursor to a pointer */

.scheme-select-cursor{
  cursor: pointer;
}